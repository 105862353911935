import React from 'react';
import DataTable from 'react-data-table-component';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";

const DataTablePage = ({slotsData,onRowSelect}) => {

const groupedData = slotsData.reduce((acc, curr) => {
     
    const date = curr.date_str;

    if (!acc[date]) {
        acc[date] = { date_str: date, slot_id: curr.slot_id };
    }

    acc[date][curr.time_str] = {
        available: `${curr.max_capacity - curr.available_capacity} / ${curr.max_capacity}`,
        slot_id: curr.slot_id
    };
    
    acc[date][`${curr.time_str}_highlight`] = curr.available_capacity === 0;
    
    return acc;
}, {});

const data = Object.values(groupedData);
const columns = [
    {
        name: 'วันที่/รอบ',
        selector: row => row.date_str,
        sortable: true,
        width: '120px',
        center: true
    },
    {
        name: '11.00',
        selector: row => row['11.00']?.available || '', // Ensure it doesn't break if undefined
        sortable: true,
        cell: row => {
            const timeData = row['11.00']; // Extract object
            return (
                <Button
                    style={{ borderRadius: "2px", color:'black', border:'white', backgroundColor: row['11.00_highlight'] ? '#ffc107' : 'transparent' }}
                    onClick={() => handleClick(row.date_str, '11.00', timeData?.available, timeData?.slot_id)}
                >
                    {timeData ? timeData.available : ''}
                </Button>
            );
        },
        width: '90px',
        center: true
    },
    {
        name: '13.30',
        selector: row => row['13.30']?.available || '', // Ensure it doesn't break if undefined
        sortable: true,
        cell: row => {
            const timeData = row['13.30']; // Extract object
            return (
                <Button
                    style={{ borderRadius: "2px", color:'black', border:'white', backgroundColor: row['13.30_highlight'] ? '#ffc107' : 'transparent' }}
                    onClick={() => handleClick(row.date_str, '13.30', timeData?.available, timeData?.slot_id)}
                >
                    {timeData ? timeData.available : ''}
                </Button>
            );
        },
        width: '90px',
        center: true
    },
    {
        name: '15.30',
        seselector: row => row['15.30']?.available || '', // Ensure it doesn't break if undefined
        sortable: true,
        cell: row => {
            const timeData = row['15.30']; // Extract object
            return (
                <Button
                    // style={{ backgroundColor: row['15.30_highlight'] ? 'yellow' : 'transparent' }}
                    style={{ borderRadius: "2px", color:'black', border:'white', backgroundColor: row['15.30_highlight'] ? '#ffc107' : 'transparent' }}

                    onClick={() => handleClick(row.date_str, '15.30', timeData?.available, timeData?.slot_id)}
                >
                    {timeData ? timeData.available : ''}
                </Button>
            );
        },
        width: '90px',
        center: true
    }
];

const handleClick = (date, time, value, slotId) => {
    onRowSelect(slotId,date); // Send value to parent or perform any other action
};

    return (
        <div style={{ width: '400px', margin: '0 auto' }}>
            <DataTable
                columns={columns}
                data={data}
                // pagination
                // paginationPerPage={10}
                // paginationRowsPerPageOptions={[10, 20, 30]}
                striped
                highlightOnHover
                noHeader
            />
        </div>
    );
};

export default DataTablePage;