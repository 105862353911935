import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginPageBc = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`${apiUrl}/api/login-bc`, {
                username,
                password,
            },
            {
                crossDomain: true,
            });

            if (response.data.success) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('isAuthenticated', 'true');
                navigate('/Forever/bc/detail');
            } else {
                alert(response.data.message || 'Incorrect username or password!');
            }
        } catch (error) {
            console.error('Error during login:', error);
            alert('There was an issue logging in. Please try again later.');
        }
    };

    return (
        <div style={{ maxWidth: '300px', position: 'fixed', top: '22%', left: '43%' }}>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter your username"
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
                <button
                    type="submit"
                    style={{ width: '100%', padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#007bff', color: '#fff' }}
                >
                    Login
                </button>
            </form>
        </div>
    );
};

export default LoginPageBc;
