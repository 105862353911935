import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DashboardPage.css";
import DataTablePage from "./DataTablePage";
import DataTableUserPage from "./DataTableUserPage";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DashboardDeatailBcPage = ({ handleLogout }) => {
  const storeId = 2;
  const campaignId = 879;

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token"); // Retrieve JWT token

  const [modal, setModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalSale, setModalSale] = useState(false);
  const [modalRemark, setModalRemark] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState(null);
  const [slotsData, setSlotsData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [inputSaleValue, setInputSaleValue] = useState("");
  const [showSale, setShowSale] = useState("");

  const [inputRemarkValue, setInputRemarkValue] = useState("");
  const [showRemark, setShowRemark] = useState("");

  const [inputStatusValue, setInputStatusValue] = useState(null);
  const [showStatus, setShowStatus] = useState(null);

  const [dataModal, setDataModal] = useState(null);
  const [newValue, setNewValue] = useState(null);
  const [dateHeaderModal, setDateHeaderModal] = useState(null);

  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertfailModalOpen, setAlertfailModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleStatus = () => setModalStatus(!modalStatus);
  const toggleSale = () => setModalSale(!modalSale);
  const toggleRemark = () => setModalRemark(!modalRemark);

  const [countTotal, setCountTotal] = useState(0);
  const [conutShowup, setCountShowup] = useState(0);
  const [constRegister, setCountRegister] = useState(0);

  const handleErrorClose = () => {
    setErrorModalOpen(false); // Close the modal
  };



  const handleRowClick = (rowData,date) => {
    console.log(rowData);
    console.log(date);
    setDateHeaderModal(date);
    
    setInputRemarkValue("");
    setShowRemark("");

    setInputSaleValue("");
    setShowSale("");

    setInputStatusValue(null);
    setShowStatus(null);

    setNewValue(null);

    console.log(rowData);
    getUserBySlotId(rowData);

    setSelectedRow(rowData);

    setModal(!modal);
  };

  useEffect(() => {
    getBookingStats();
    fetchDateAndTimeBy();
  }, []);

  const fetchDateAndTimeBy = async () => {
    // setLoading(true);
    // try {
    const response = await axios.post(
      `${apiUrl}/getDateAndTimeByBc`,
      {
        allbooking: false,
        storeId: storeId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        headers: {
          Authorization: `Bearer ${token}`, // Send JWT token in header
        },
        crossdomain: true,
      }
    );

    if (response.status === 200) {
      setSlotsData(response.data.stores);
    } else {
      console.error("Failed to fetch stores:", response.data.message);
    }
    // } catch (error) {
    //   console.error("Error fetching store options:", error);
    // } finally {
    //   setLoading(false);
    // }
  };

  const getUserBySlotId = async (slotId) => {
    // setLoading(true);
    // try {
    const response = await axios.post(
      `${apiUrl}/getUserBySlotId`,
      {
        slotId: slotId,
        campaignId:campaignId,
        storeId:storeId
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        headers: {
          Authorization: `Bearer ${token}`, // Send JWT token in header
        },
        crossdomain: true,
      }
    );
    console.log(response.data);
    setUserData(response.data);

    // if (response.status === 200) {
    //   setSlotsData(response.data.stores);
    // } else {
    //   console.error("Failed to fetch stores:", response.data.message);
    // }
    // } catch (error) {
    //   console.error("Error fetching store options:", error);
    // } finally {
    //   setLoading(false);
    // }
  };
  const inputStatus = async (row) => {
    setInputStatusValue(row.status_bc);
    setDataModal(row);
    toggleStatus();
  };

  const updateStatus = async (status) => {
    setDataModal((prevDataModal) => {
      const updatedDataModal = { ...prevDataModal, status_bc: status ? 1 : 2 };
      setShowStatus(status ? 1 : 2);
      updateRegisterFact(updatedDataModal);

      setNewValue(updatedDataModal);
      toggle();
    });
    toggleStatus();
  };

  const inputSale = async (row) => {
    console.log(row);
    setInputSaleValue(row.sale_bc);
    setDataModal(row);
    toggleSale();
  };
  const updateSale = async (status) => {
    if (status) {
      setDataModal((prevDataModal) => {
        const updatedDataModal = { ...prevDataModal, sale_bc: inputSaleValue };
        setShowSale(inputSaleValue);
        updateRegisterFact(updatedDataModal);

        setNewValue(updatedDataModal);
        toggle();
      });
    } else {
      setInputSaleValue("");
    }
    toggleSale();
  };
  const inputRemark = async (row) => {
    console.log(row);
    setInputRemarkValue(row.remarks);
    setDataModal(row);
    toggleRemark();
  };
  const updateRemark = async (status) => {
    if (status) {
      setDataModal((prevDataModal) => {
        const updatedDataModal = {
          ...prevDataModal,
          remarks: inputRemarkValue,
        };
        setShowRemark(inputRemarkValue);
        updateRegisterFact(updatedDataModal);

        setNewValue(updatedDataModal);
        toggle();
      });
    } else {
      setInputRemarkValue("");
    }
    toggleRemark();
  };

  const updateRegisterFact = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/updateRegisterFact`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        headers: {
          Authorization: `Bearer ${token}`, // Send JWT token in header
        },
        crossdomain: true,
      });
      if (response.status === 200 && response.data.message === "Update successful.") {
        getBookingStats();
        fetchDateAndTimeBy();
        setAlertModalOpen(true);
        console.log("Update successful:", response.data);
      }else if (response.status === 200) {
        setAlertfailModalOpen(true);
        console.error("Failed to update:", response.data.message);
      }
    } catch (error) {      
      setErrorModalOpen(true); // Open the error modal
      console.error("Error updating register fact:", error);
    }
  };

  const alertClose = () => {
    setAlertModalOpen(false);
  };
  const alertfailClose = () => {
    setAlertfailModalOpen(false);
  };

  const onLogoutClick = () => {
    handleLogout(); // Call the handleLogout function passed as a prop
    navigate("/Forever/bc/login"); // Redirect to the login page after logout
  };

  const getBookingStats = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/getBookingStats`,
        {store_id:storeId, campaign_id:campaignId},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Send JWT token in header
          },
          crossdomain: true,
        }
      );

      if (response.status === 200) {
        console.log("Booking stats:", response.data);
        console.log(response.data.totalCapacity);
        setCountTotal(response.data.totalCapacity);
        setCountShowup(response.data.totalBookedSlots);
        setCountRegister(response.data.totalRegistrations);
      } else {
        console.error("Failed to fetch booking stats:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching booking stats:", error);
    }
  };
  return (
    <div>
      <Container style={{ justifyContent: "center" }}>
        <Row className="justify-content-center">
          <Col xs="12" md="10" lg="8" className="content">
            <h1>Forever : Siam Paragon</h1>
            <h2>Booking : {constRegister}/{countTotal}  Show up : {conutShowup}/{constRegister}</h2>
            <DataTablePage
              centered
              slotsData={slotsData}
              onRowSelect={handleRowClick}
              totalCapacity={countTotal}
              totalBookedSlots={conutShowup} 
              totalRegistrations={constRegister}
            />

            <Modal fullscreen centered isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>วันที่ {dateHeaderModal}</ModalHeader>
              <ModalBody>
                <DataTableUserPage
                  selectedRow={selectedRow}
                  userData={userData}
                  inputStatus={inputStatus}
                  inputSale={inputSale}
                  inputRemark={inputRemark}
                  showRemark={showRemark}
                  showSale={showSale}
                  newValue={newValue}
                  showStatus={showStatus}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={toggle}
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                >
                  ปิด
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={modalStatus} toggle={toggleStatus} centered>
              <ModalHeader toggle={toggleStatus}>กรุณาเลือกสถานะ</ModalHeader>
              <ModalBody>
                <Button
                  color="success"
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => updateStatus(true)}
                >
                  มารับบริการ
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  color="danger"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => updateStatus(false)}
                >
                  ยกเลิกเข้ารับบริการ
                </Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                >
                  ยกเลิก
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={modalSale} toggle={toggleSale} centered>
              <ModalHeader toggle={toggleSale}>กรุณาระบุยอดซื้อ</ModalHeader>
              <ModalBody>
                <Input
                  type="number"
                  step="0.01"
                  placeholder="กรุณาระบุยอดซื้อ"
                  onChange={(e) => setInputSaleValue(e.target.value)}
                  value={inputSaleValue}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => updateSale(true)}
                >
                  ยืนยัน
                </Button>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => updateSale(false)}
                >
                  ยกเลิก
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={modalRemark} toggle={toggleRemark} centered>
              <ModalHeader toggle={toggleRemark}>กรุณาระบุหมายเหตุ</ModalHeader>
              <ModalBody>
                <Input
                  type="textarea"
                  rows={5}
                  onChange={(e) => setInputRemarkValue(e.target.value)}
                  value={inputRemarkValue}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => updateRemark(true)}
                >
                  ยืนยัน
                </Button>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => updateRemark(false)}
                >
                  ยกเลิก
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={alertModalOpen} toggle={alertClose}>
              <ModalHeader toggle={alertClose}>แจ้งเตือน</ModalHeader>
              <ModalBody>
                บันทึกข้อมูลสำเร็จ
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "1px",
                  }}
                  onClick={alertClose}
                >
                  ตกลง
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={alertfailModalOpen} toggle={alertfailClose}>
              <ModalHeader toggle={alertfailClose}>แจ้งเตือน</ModalHeader>
              <ModalBody>
                บันทึกข้อมูลไม่สำเร็จ โปรดลองอีกครั้ง
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "1px",
                  }}
                  onClick={alertfailClose}
                >
                  ตกลง
                </Button>
              </ModalFooter>
            </Modal>
            {/* Error Modal */}
                    <Modal isOpen={errorModalOpen} toggle={handleErrorClose}>
                      <ModalHeader toggle={handleErrorClose}>
                        มีปัญหาในการเชื่อมต่อ
                      </ModalHeader>
                      <ModalBody>
                        ระบบมีปัญหาในการส่งข้อมูล กรุณาลองอีกครั้งในภายหลัง
                        <br />
                        There was an issue submitting your data. Please try again later.
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          style={{
                            backgroundColor: "#000",
                            borderColor: "#000",
                            color: "#FFF",
                            borderRadius: "1px",
                          }}
                          onClick={handleErrorClose}
                        >
                          ตกลง
                        </Button>
                      </ModalFooter>
                    </Modal>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button
              style={{
                width: "150px",
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={onLogoutClick}
            >
              Logout
            </Button>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </Container>
    </div>
  );
};

export default DashboardDeatailBcPage;
