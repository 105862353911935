import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DashboardPage.css";
import DataTablePage from "./DataTablePage";
import DataTableUserPage from "./DataTableUserPage";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Constants
const STORE_ID = 2;
const CAMPAIGN_ID = 879;

// Custom hook for managing modals
const useModalState = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return [isOpen, toggle];
};

const DashboardDeatailBcSiamParagonPage = ({ handleLogout }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  

  // Loading states
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Modal states using custom hook
  const [mainModal, toggleMainModal] = useModalState();
  const [statusModal, toggleStatusModal] = useModalState();
  const [saleModal, toggleSaleModal] = useModalState();
  const [remarkModal, toggleRemarkModal] = useModalState();
  const [alertModal, toggleAlertModal] = useModalState();
  const [alertFailModal, toggleAlertFailModal] = useModalState();
  const [errorModal, toggleErrorModal] = useModalState();

  // Data states
  const [slotsData, setSlotsData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dateHeaderModal, setDateHeaderModal] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  // Form states
  const [saleValue, setSaleValue] = useState("");
  const [remarkValue, setRemarkValue] = useState("");
  const [showStatus, setShowStatus] = useState(null);
  const [showSale, setShowSale] = useState("");
  const [showRemark, setShowRemark] = useState("");

  // Stats states
  const [stats, setStats] = useState({
    totalCapacity: 0,
    totalShowup: 0,
    totalRegister: 0,
    purchaseCount: 0,
    totalSales: 0,
  });

  // Clear all input states
  const clearInputs = () => {
    setSaleValue("");
    setRemarkValue("");
    setShowStatus(null);
    setShowSale("");
    setShowRemark("");
    setSelectedRow(null);
  };

  // Handle row click in main table
  const handleRowClick = async (rowData, date) => {
    setDateHeaderModal(date);
    clearInputs();
    setLoading(true);
    try {
      toggleMainModal();
      await getUserBySlotId(rowData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toggleErrorModal();
    } finally {
      setLoading(false);
    }
  };

  // API calls
  const getUserBySlotId = async (slotId) => {
    const response = await axios.post(
      `${apiUrl}/getUserBySlotId`,
      {
        slotId: slotId,
        campaignId: CAMPAIGN_ID,
        storeId: STORE_ID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    setUserData(response.data);
  };

  const updateRegisterFact = async (data) => {
    setIsUpdating(true);
    try {
      const response = await axios.post(
        `${apiUrl}/updateRegisterFact`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.message === "Update successful.") {
        await Promise.all([
          getBookingStats(),
          fetchDateAndTimeBy()
        ]);
        toggleAlertModal();
      } else {
        toggleAlertFailModal();
      }
    } catch (error) {
      console.error("Error updating register fact:", error);
      toggleErrorModal();
    } finally {
      setIsUpdating(false);
      toggleMainModal();
    }
  };

  const getBookingStats = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/getBookingStats`,
        { store_id: STORE_ID, campaign_id: CAMPAIGN_ID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setStats({
          totalCapacity: response.data.totalCapacity,
          totalShowup: response.data.totalBookedSlots,
          totalRegister: response.data.totalRegistrations,
          purchaseCount: response.data.purchaseCount,
          totalSales: response.data.totalSales
        });
      }
    } catch (error) {
      console.error("Error fetching booking stats:", error);
      toggleErrorModal();
    }
  };

  const fetchDateAndTimeBy = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/getDateAndTimeByBc`,
        {
          allbooking: false,
          storeId: STORE_ID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSlotsData(response.data.stores);
      }
    } catch (error) {
      console.error("Error fetching date and time:", error);
      toggleErrorModal();
    }
  };

  // Handlers for user actions
  const handleStatusChange = (row) => {
    setSelectedRow(row);
    toggleStatusModal();
  };

  const handleSaleChange = (row) => {
    setSelectedRow(row);
    setSaleValue(row.sale_bc || "");
    toggleSaleModal();
  };

  const handleRemarkChange = (row) => {
    setSelectedRow(row);
    setRemarkValue(row.remarks || "");
    toggleRemarkModal();
  };

  const handleStatusUpdate = (status) => {
    if (selectedRow) {
      const updatedRow = { ...selectedRow, status_bc: status ? 1 : 2 };
      setShowStatus(status ? 1 : 2);
      updateRegisterFact(updatedRow);
    }
    toggleStatusModal();
  };

  const handleSaleUpdate = (confirmed) => {
    if (confirmed && selectedRow) {
      const updatedRow = { ...selectedRow, sale_bc: saleValue };
      setShowSale(saleValue);
      updateRegisterFact(updatedRow);
    }
    toggleSaleModal();
  };

  const handleRemarkUpdate = (confirmed) => {
    if (confirmed && selectedRow) {
      const updatedRow = { ...selectedRow, remarks: remarkValue };
      setShowRemark(remarkValue);
      updateRegisterFact(updatedRow);
    }
    toggleRemarkModal();
  };

  const handleLogoutClick = () => {
    handleLogout();
    navigate("/Forever/SiamParagon/bc/login");
  };

  // Initial data fetch
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsInitialLoading(true);
      try {
        await Promise.all([
          getBookingStats(),
          fetchDateAndTimeBy()
        ]);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        toggleErrorModal();
      } finally {
        setIsInitialLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const formatNumber = (value) => {
    if (!value) return '';
    // Convert to number and format with thousand separator and 2 decimal places
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  if (isInitialLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner color="dark" />
      </div>
    );
  }

  return (
    <div>
      <Container style={{ justifyContent: "center" }}>
        <Row className="justify-content-center">
          <Col xs="12" md="10" lg="8" className="content">
            <h1 className="text-center">Forever : Siam Paragon</h1>
            <Row>
              <Col md="2" lg="2">
              </Col>
              <Col xs="5" md="3" lg="3">
                <h2 className="text-right">
                  Booking : {stats.totalShowup}/{stats.totalCapacity} 
                </h2>
              </Col>
              <Col xs="7" md="5" lg="5">
                <h2 className="text-right">
                  Show up : {stats.totalRegister}/{stats.totalShowup}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col  md="2" lg="2">
              </Col>
              <Col xs="5" md="3" lg="3">
                <h2 className="text-right">
                Purchaser : {stats.purchaseCount}
                </h2>
              </Col>
              <Col xs="7" md="5" lg="5">
                <h2 className="text-right">
                Total Sales: {formatNumber(stats.totalSales)} THB
                </h2>
              </Col>
            </Row>

            {/* <Row>
              <Col xs="2" md="2" lg="2">
              </Col>
              <Col xs="4" md="4" lg="4">
                <h2 className="text-center">
                  Booking : {stats.totalRegister}/{stats.totalCapacity} Show up : {stats.totalShowup}/{stats.totalRegister} <br />
                  Purchaser : {stats.purchaseCount} TotalSales : {stats.totalSales}
                </h2>
              </Col>
            </Row> */}
            
            <DataTablePage
              centered
              slotsData={slotsData}
              onRowSelect={handleRowClick}
            />

            {/* Main Modal */}
            <Modal fullscreen centered isOpen={mainModal} toggle={toggleMainModal}>
              <ModalHeader toggle={toggleMainModal}>
                วันที่ {dateHeaderModal}
              </ModalHeader>
              <ModalBody>
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <Spinner color="dark" />
                  </div>
                ) : (
                  <DataTableUserPage
                    userData={userData}
                    onStatusChange={handleStatusChange}
                    onSaleChange={handleSaleChange}
                    onRemarkChange={handleRemarkChange}
                    showStatus={showStatus}
                    showSale={showSale}
                    showRemark={showRemark}
                    isUpdating={isUpdating}
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={toggleMainModal}
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  disabled={isUpdating}
                >
                  ปิด
                </Button>
              </ModalFooter>
            </Modal>

            {/* Status Modal */}
            <Modal isOpen={statusModal} toggle={toggleStatusModal} centered>
              <ModalHeader toggle={toggleStatusModal}>กรุณาเลือกสถานะ</ModalHeader>
              <ModalBody>
                <Button
                  color="success"
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => handleStatusUpdate(true)}
                  disabled={isUpdating}
                >
                  มารับบริการ
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  color="danger"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => handleStatusUpdate(false)}
                  disabled={isUpdating}
                >
                  ยกเลิกเข้ารับบริการ
                </Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={toggleStatusModal}
                  disabled={isUpdating}
                >
                  ยกเลิก
                </Button>
              </ModalFooter>
            </Modal>

            {/* Sale Modal */}
            <Modal isOpen={saleModal} toggle={toggleSaleModal} centered>
              <ModalHeader toggle={toggleSaleModal}>กรุณาระบุยอดซื้อ</ModalHeader>
              <ModalBody>
                <Input
                  type="number"
                  step="0.01"
                  placeholder="กรุณาระบุยอดซื้อ"
                  onChange={(e) => setSaleValue(e.target.value)}
                  value={saleValue}
                  disabled={isUpdating}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => handleSaleUpdate(true)}
                  disabled={isUpdating}
                >
                  ยืนยัน
                </Button>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => handleSaleUpdate(false)}
                  disabled={isUpdating}
                >
                  ยกเลิก
                </Button>
              </ModalFooter>
            </Modal>

            {/* Remark Modal */}
            <Modal isOpen={remarkModal} toggle={toggleRemarkModal} centered>
              <ModalHeader toggle={toggleRemarkModal}>กรุณาระบุหมายเหตุ</ModalHeader>
              <ModalBody>
                <Input
                  type="textarea"
                  rows={5}
                  onChange={(e) => setRemarkValue(e.target.value)}
                  value={remarkValue}
                  disabled={isUpdating}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => handleRemarkUpdate(true)}
                  disabled={isUpdating}
                >
                  ยืนยัน
                </Button>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "2px",
                  }}
                  onClick={() => handleRemarkUpdate(false)}
                  disabled={isUpdating}
                >
                  ยกเลิก
                </Button>
              </ModalFooter>
            </Modal>

            {/* Alert Modals */}
            <Modal isOpen={alertModal} toggle={toggleAlertModal}>
              <ModalHeader toggle={toggleAlertModal}>แจ้งเตือน</ModalHeader>
              <ModalBody>บันทึกข้อมูลสำเร็จ</ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "1px",
                  }}
                  onClick={toggleAlertModal}
                  disabled={isUpdating}
                >
                  ตกลง
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={alertFailModal} toggle={toggleAlertFailModal}>
              <ModalHeader toggle={toggleAlertFailModal}>แจ้งเตือน</ModalHeader>
              <ModalBody>บันทึกข้อมูลไม่สำเร็จ โปรดลองอีกครั้ง</ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "1px",
                  }}
                  onClick={toggleAlertFailModal}
                  disabled={isUpdating}
                >
                  ตกลง
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={errorModal} toggle={toggleErrorModal}>
              <ModalHeader toggle={toggleErrorModal}>
                มีปัญหาในการเชื่อมต่อ
              </ModalHeader>
              <ModalBody>
                ระบบมีปัญหาในการส่งข้อมูล กรุณาลองอีกครั้งในภายหลัง
                <br />
                There was an issue submitting your data. Please try again later.
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    backgroundColor: "#000",
                    borderColor: "#000",
                    color: "#FFF",
                    borderRadius: "1px",
                  }}
                  onClick={toggleErrorModal}
                  disabled={isUpdating}
                >
                  ตกลง
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button
              style={{
                width: "150px",
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleLogoutClick}
              disabled={isUpdating}
            >
              Logout
            </Button>
          </Col>
        </Row>
        <br />
        <br />
      </Container>
    </div>
  );
};

export default DashboardDeatailBcSiamParagonPage;
