import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import './ConsentModal.css'; // Importing the CSS file
import eventImage from "./images/christian-dior-new3874.jpg"; // Adjust the path as necessary

function ConsentLink() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleConsent = () => {
    // Add logic for handling consent here, like storing consent status
    toggleModal();
  };

  const tableStyle = {
    // width: '100%',
    borderCollapse: 'collapse',
    fontSize:'14px',
    fontFamily: 'Noto Sans Thai,sans-serif',
    overflowX: 'auto', /* Enables horizontal scrolling */
    maxWidth: '100%',  /* Ensures the container doesn't exceed the width of its parent */
    overflowY: 'auto' /* Enable vertical scrolling */
  };

  const cellStyle1 = {
    padding: '12px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    color: '#333',
    whiteSpace: 'nowrap' /* ทำให้ข้อมูลไม่ตัดบรรทัด */

  };

  const cellStyle2 = {
    padding: '12px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    color: '#333',
  };

  return (
    <label>

      <a
        style={{
          // backgroundColor: "#000",
          // borderColor: "#000",
          color: "#000",
          // borderRadius: "1px",
        }}
        href="#"
        onClick={toggleModal}
      >
       (นโยบายความเป็นส่วนตัว)
      </a>

      <Modal isOpen={isModalOpen} toggle={toggleModal} fullscreen>
        <ModalHeader toggle={toggleModal}>Consent</ModalHeader>
        <ModalBody style={{overflowX: 'hidden'}}>
          <div>
      <div className="text-center">
        <img
          src={eventImage}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "60%", height: "auto", maxWidth: "500px" }}
        />
      </div>
          <div className="Container">
            <header className="PageHeader">
              <div className="SectionHeader SectionHeader--center">
                <h1 className="SectionHeader__Heading Heading u-h1">
                  PRIVACY &amp; DATA PROTECTION
                </h1>
              </div>
            </header>
            <br/>
            <div className="PageContent Rte">
              <p>
                <span>
                  บริษัท แอลวีเอ็มเอช เพอร์ฟูม แอนด์ คอสเมติกส์ (ประเทศไทย)
                  จำกัด (“
                </span>
                <b>
                  <span>เรา</span>
                </b>
                <span>” “</span>
                <b>
                  <span>ของเรา</span>
                </b>
                <span>” หรือ</span>
                <span> “</span>
                <b>
                  <span>บริษัทฯ</span>
                </b>
                <span>”) </span>
                <span>
                  &nbsp;
                  <br />
                </span>
                <span>
                  ให้ความสำคัญในสิทธิในข้อมูลส่วนบุคคลของท่าน
                  และเรามีความมุ่งมั่นที่จะเก็บรวบรวม ใช้ เปิดเผย
                  และโอนข้อมูลส่วนบุคคลของท่าน (“
                </span>
                <b>
                  <span>ประมวลผล</span>
                </b>
                <span>” หรือ “</span>
                <b>
                  <span>การประมวลผล</span>
                </b>
                <span>”) </span>
                <span>
                  ด้วยความรับผิดชอบ ด้วยเหตุนี้
                  เราจึงได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลสำหรับลูกค้าฉบับนี้
                  (“
                </span>
                <b>
                  <span>นโยบายฯ</span>
                </b>
                <span>
                  ”)
                  เพื่ออธิบายให้ท่านทราบถึงรายละเอียดเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่าน
                  ภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (“
                </span>
                <b>
                  <span>กฎหมายคุ้มครองข้อมูลส่วนบุคคล</span>
                </b>
                <span>”)</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  โปรดทราบว่า
                  ท่านมีความจำเป็นที่จะต้องอ่านและทำความเข้าใจเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่านซึ่งได้ระบุอยู่ในนโยบายฯ
                  ฉบับนี้
                  เพื่อที่ท่านจะได้สามารถเข้าใจถึงวิธีการที่เราประมวลข้อมูลส่วนบุคคลของท่าน
                  และรู้สิทธิของเจ้าของข้อมูลส่วนบุคคลที่ท่านพึงมี
                  โดยเนื้อหาของนโยบายฯ ฉบับนี้ประกอบด้วย ดังต่อไปนี้
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>ขอบเขตของนโยบายฯ ฉบับนี้</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>นโยบายฯ ฉบับนี้มีผลใช้บังคับกับท่าน</span>{" "}
                <span>หากท่านเป็นบุคคลดังต่อไปนี้</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <div className="TableWrapper">
                <table  d style={tableStyle}>
                  <tbody>
                    <tr >
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ประเภทของเจ้าของข้อมูลส่วนบุคคล</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                      <td style={cellStyle2}>
                        <p>
                          <b>
                            <span>คำนิยาม</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr >
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>บุคคลผู้เกี่ยวข้อง</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle2}>
                        <p>
                          <span>บุคคลใด</span>{" "}
                          <span>
                            ๆ ที่เกี่ยวข้องกับลูกค้า เช่น ตัวแทน ผู้รับของขวัญ
                          </span>{" "}
                          <span>ผู้ที่อาจเข้าร่วมแคมเปญ</span>{" "}
                          <span>กิจกรรม</span>{" "}
                          <span>หรือประชุมเชิงปฏิบัติการกับลูกค้า</span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr >
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ลูกค้า</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle2}>
                        <p>
                          <span>
                            ลูกค้าที่เป็นสมาชิกและลูกค้าที่ยังไม่เป็นสมาชิก
                          </span>{" "}
                          <span>ซึ่งหมายความรวมกัน</span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="2" colspan="1" style={cellStyle1}>
                        <p>
                          <b>
                            <span>ลูกค้าที่เป็นสมาชิก</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle2}>
                        <p>
                          <span>
                            ลูกค้า (บุคคลธรรมดา)
                            ที่ลงทะเบียนเป็นสมาชิกลูกค้าของบริษัทฯ
                            ผ่านทางเว็บไซต์ของเรา ได้แก่
                          </span>
                          <span> https://shop.dior.co.th/ </span>
                          <span>และ/หรือ </span>
                          <span>LINE Official Account </span>
                          <span>และ/หรือ เคาน์เตอร์สโตร์</span>{" "}
                          <span>และ/หรือ&nbsp;</span>
                          {/* <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          </span> */}
                          <span>
                            บูทีคสโตร์ของเรา หรือโดยวิธีอื่นๆ
                            เพื่อการใช้งานส่วนตัว
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <span>
                            ผู้ติดต่อหรือตัวแทนผู้ซึ่งได้รับมอบอำนาจจาก
                          </span>
                          <span>
                            &nbsp;
                            <br />
                          </span>
                          <span>
                            นิติบุคคลที่ลงทะเบียนเป็นสมาชิกลูกค้าของบริษัทฯ
                            ผ่านเว็บไซต์ของเรา ได้แก่
                          </span>
                          <span> https://shop.dior.co.th/ </span>
                          <span>และ/หรือ </span>
                          <span>LINE Official Account </span>
                          <span>และ/หรือ</span>{" "}
                          <span>
                            เคาน์เตอร์สโตร์ และ/หรือ บูทีคสโตร์ของเรา
                            หรือโดยวิธีอื่นๆ
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle2}>
                        <p>
                          <b>
                            <span>ลูกค้าที่ยังไม่เป็นสมาชิก</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle2}>
                        <p>
                          <span>
                            บุคคลธรรมดาที่ยังไม่ลงทะเบียนเป็นสมาชิกลูกค้าของบริษัทฯ&nbsp;
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>ลูกค้าซึ่งเป็นผู้เยาว์</span>
                  </b>
                  <b>
                    <span>&nbsp;</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  เราอาจเก็บรวบรวมข้อมูลส่วนบุคคลตามที่อธิบายไว้ด้านล่างจากบุคคลที่ยังไม่บรรลุนิติภาวะตามกฎหมายของประเทศไทย
                  หากท่านเป็นผู้เยาว์ โดยเฉพาะในช่วงอายุมากกว่า{" "}
                </span>
                <span>10 ปี </span>
                <span>แต่ไม่ถึง </span>
                <span>20</span>
                <span> ปี</span>{" "}
                <span>
                  เราขอแนะนำอย่างยิ่งให้พ่อแม่หรือผู้ปกครองของท่านอ่านนโยบายฯ
                  ฉบับนี้ ร่วมกับท่าน
                  และพ่อแม่หรือผู้ปกครองของผู้เยาว์ต้องให้ความยินยอม (ถ้าจำเป็น)
                  และให้คำแนะนำก่อนที่ผู้เยาว์จะให้ข้อมูลส่วนบุคคลของตนเอง
                </span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>ประเภทของข้อมูลส่วนบุคคลที่เราประมวลผล</span>
                  </b>
                  <b>
                    <span>&nbsp;</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  โดยทั่วไป
                  เราจะทำการเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลตามประเภทของข้อมูลส่วนบุคคล
                  ดังต่อไปนี้
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <div className="TableWrapper">
                <table  d style={tableStyle}>
                  <tbody>
                    <tr >
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ประเภทของข้อมูลส่วนบุคคล</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ข้อมูลส่วนบุคคลที่จัดเก็บ</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                    <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              ข้อมูลส่วนบุคคลเกี่ยวกับอุปกรณ์และการเข้าถึงข้อมูล
                            </span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>ข้อมูลเกี่ยวกับการเชื่อมต่ออินเทอร์เน็ต (</span>
                          <span>IP address) </span>
                          <span>ข้อมูลคุกกี้</span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                    <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>ชื่อ</span>
                          <span>-</span>
                          <span>
                            นามสกุล คำนำหน้านาม หมายเลขโทรศัพท์มือถือ
                            หมายเลขโทรศัพท์
                          </span>{" "}
                          <span>
                            ที่อยู่ อีเมล วันเกิด อายุ เพศ
                            หมายเลขบัตรประจำตัวประชาชนและข้อมูลที่ระบุอยู่ในบัตรประจำตัวประชาชน
                            หมายเลขหนังสือเดินทางและข้อมูลที่ระบุอยู่ในหนังสือเดินทาง
                            ข้อมูลเที่ยวบินและการเดินทาง
                            หมายเลขประจำตัวผู้เสียภาษีอากร สัญชาติ{" "}
                          </span>
                          <span>LINE ID </span>
                          <span>
                            ลายมือชื่อ
                            ชื่อผู้ใช้และรหัสผ่านของบัญชีออนไลน์ของลูกค้า
                            ประวัติการซื้อสินค้า
                          </span>{" "}
                          <span>รูปภาพ</span> <span>วิดีโอ</span>
                          <span>&nbsp;</span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ข้อมูลส่วนบุคคลด้านการเงิน</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>
                            หมายเลขบัญชีธนาคารของท่านและข้อมูลบัญชีธนาคาร
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ข้อมูลการชำระเงิน</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>
                            เอกสารหรือข้อมูลซึ่งแสดงหลักฐานการชำระเงิน&nbsp;
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ข้อมูลการทำงาน</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>
                            เบอร์โทรศัพท์ของบริษัท ชื่อบริษัทและที่อยู่ ตำแหน่ง
                            อาชีพ&nbsp;
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ข้อมูลส่วนบุคคลของบุคคลผู้เกี่ยวข้อง</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>
                            ชื่อ-นามสกุล ที่อยู่ หมายเลขโทรศัพท์ รูปภาพ วิดีโอ
                          </span>
                          <span>&nbsp;</span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ข้อมูลส่วนบุคคลที่มีความอ่อนไหว</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>บริษัทฯ</span>{" "}
                          <span>
                            อาจประมวลผลข้อมูลส่วนบุคคลที่เกี่ยวข้องกับสุขภาพของท่าน
                            เช่น อาการ โรค อาการแพ้ รูปภาพของอาการแพ้
                            ใบรับรองแพทย์ การรักษาพยาบาล เป็นต้น
                          </span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่านที่อาจปรากฏบนสำเนาบัตรประจำตัวประชาชน
                            ได้แก่ ศาสนา
                          </span>{" "}
                          <span>และ/หรือ</span>{" "}
                          <span>หมู่โลหิต อย่างไรก็ตาม บริษัทฯ</span>{" "}
                          <span>
                            ไม่มีเจตนาที่จะประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวดังกล่าว
                            ดังนั้น เราจะขอให้ท่าน
                          </span>{" "}
                          <span>ปิดบัง</span> <span>ขีดฆ่า</span>{" "}
                          <span>หรือนำข้อมูลศาสนา</span> <span>และ/หรือ</span>{" "}
                          <span>
                            หมู่โลหิตออกก่อนที่จะส่งมอบสำเนาบัตรประจำตัวประชาชนของท่านให้แก่บริษัทฯ
                          </span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ในกรณีที่ข้อมูลส่วนบุคคลที่มีความอ่อนไหวดังกล่าวยังคงปรากฏบนสำเนาบัตรประจำตัวประชาชน
                            บริษัทฯ
                          </span>{" "}
                          <span>
                            อาจปิดบังหรือขีดฆ่าข้อมูลส่วนบุคคลที่มีความอ่อนไหวดังกล่าวได้ตลอดเวลา
                            เพื่อให้เป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                          </span>
                          <span> (</span>
                          <span>ซึ่งกำหนดให้บริษัทฯ</span>{" "}
                          <span>
                            เก็บรวบรวมข้อมูลส่วนบุคคลเฉพาะที่จำเป็นและเกี่ยวข้องกับการดำเนินธุรกิจของเรา)
                          </span>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>ในกรณีที่บริษัทฯ</span>{" "}
                <span>
                  จะเก็บรวบรวมข้อมูลส่วนบุคคลอื่นนอกเหนือจากที่กำหนดไว้ในนโยบายฯ
                  ฉบับนี้{" "}
                </span>
                <span>
                  &nbsp;
                  <br />
                </span>
                <span>บริษัทฯ</span>{" "}
                <span>
                  จะแจ้งให้ท่านทราบเกี่ยวกับการเก็บรวบรวมหรือการประมวลผลข้อมูลส่วนบุคคลและอาจขอความยินยอมจากท่าน
                  (หากจำเป็น)
                  ตามเงื่อนไขที่กำหนดโดยกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>วิธีการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>โดยทั่วไป บริษัทฯ</span>{" "}
                <span>
                  จะเก็บรวบรวมข้อมูลส่วนบุคคลโดยตรงจากท่าน อย่างไรก็ตาม
                  ในกรณีที่จำเป็น
                </span>{" "}
                <span>บริษัทฯ</span>{" "}
                <span>
                  จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากแหล่งอื่น ซึ่งบริษัทฯ
                </span>{" "}
                <span>
                  รับรองว่าข้อมูลส่วนบุคคลของท่านจะถูกเก็บรวบรวมและคุ้มครองตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  ในกรณีที่ท่านให้ข้อมูลส่วนบุคคลของบุคคลภายนอกรวมถึงบุคคลผู้เกี่ยวข้องแก่บริษัทฯ
                </span>{" "}
                <span>
                  &nbsp;
                  <br />
                </span>
                <span>
                  ท่านรับรองว่าท่านได้แจ้งให้บุคคลดังกล่าวทราบถึงการที่บริษัทฯ
                  ประมวลผลข้อมูลส่วนบุคคลของบุคคลนั้น ตามที่อธิบายไว้ในนโยบายฯ
                  ฉบับนี้แล้ว นอกจากนี้
                  ในกรณีที่มีความจำเป็นต้องได้รับความยินยอมในการประมวลผลข้อมูลส่วนบุคคล
                  ท่านตกลงที่จะช่วยเหลือเพื่อให้บริษัทฯ
                </span>{" "}
                <span>
                  ได้รับความยินยอมที่ถูกต้องและบังคับใช้ได้จากบุคคลดังกล่าวตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>วัตถุประสงค์ในประมวลผลข้อมูลส่วนบุคคลของท่าน</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>โดยทั่วไป บริษัทฯ</span>{" "}
                <span>
                  ทำการประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังนี้
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span >
                  &nbsp;
                </span>
              </p>
              <div className="TableWrapper">
                <table  d style={tableStyle}>
                  <tbody>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>วัตถุประสงค์</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ประเภทของข้อมูลส่วนบุคคล</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>ฐานทางกฎหมาย</span>
                          </b>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              เพื่อเข้าทำสัญญาหรือก่อนิติสัมพันธ์กับท่านหรือนิติบุคคลที่ท่านได้รับการแต่งตั้งให้เป็นตัวแทน
                              และเพื่อดำเนินการและปฏิบัติตามภาระผูกพันภายใต้สัญญาหรือนิติสัมพันธ์ดังกล่าว
                            </span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ข้อมูลส่วนบุคคลของท่านมีความจำเป็นสำหรับวัตถุประสงค์
                          </span>{" "}
                          <span>ดังต่อไปนี้</span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <ul>
                          <li >
                            <span>เพื่อสร้างและลงทะเบียนบัญชีลูกค้า&nbsp;</span>
                            <span >
                              &nbsp;
                            </span>
                          </li>
                          <li >
                            <span>เพื่อดำเนินการชำระเงินค่าสินค้า</span>
                            <span >
                              &nbsp;
                            </span>
                          </li>
                          <li >
                            <span>
                              เพื่อดำเนินการส่งสินค้าที่ท่านสั่งซื้อให้ท่านหรือบุคคลผู้เกี่ยวข้อง
                            </span>{" "}
                            <span>และ</span>
                            <span >
                              &nbsp;
                            </span>
                          </li>
                          <li >
                            <span>เพื่อส่งการ์ดอวยพรไปยังผู้รับของขวัญ</span>
                            <span >
                              &nbsp;
                            </span>
                          </li>
                        </ul>
                        <p>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการชำระเงิน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการทำงาน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลของบุคคลผู้เกี่ยวข้อง</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <b>
                            <span>หมายเหตุ</span>
                          </b>
                          <span>
                            : หากท่านไม่ให้ข้อมูลส่วนบุคคลใด ๆ
                            ที่จำเป็นสำหรับการสร้างและลงทะเบียนบัญชีของลูกค้าในระบบของเรา
                            การดำเนินการชำระเงินให้สำเร็จ และการส่งสินค้า
                            เราอาจไม่สามารถเข้าทำสัญญากับท่าน รวมทั้ง
                            ไม่สามารถดำเนินการหรือปฏิบัติตามภาระผูกพันของเราตามที่ระบุในสัญญาหรือนิติสัมพันธ์ดังกล่าว
                            (ไม่ว่าทั้งหมดหรือบางส่วน) รวมทั้ง
                            ไม่สามารถดำเนินการตามคำสั่งซื้อและการชำระเงินของท่าน
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>ประโยชน์โดยชอบด้วยกฎหมาย</span>
                            <span> (</span>
                            <span>
                              สำหรับลูกค้าที่เป็นนิติบุคคลและสำหรับการประมวลผลข้อมูลส่วนบุคคลของบุคคลผู้เกี่ยวข้องเพื่อดำเนินการส่งสินค้าและการ์ดอวยพร
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>การปฏิบัติตามสัญญา </span>
                            <span>(</span>
                            <span>สำหรับลูกค้าที่เป็นบุคคลธรรมดา</span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              เพื่อมอบบริการด้านความงาม ผลิตภัณฑ์ใหม่
                              ผลิตภัณฑ์ทดลอง การแลกรับของขวัญหรือของรางวัล
                            </span>
                          </b>
                          <b>
                            <span>&nbsp;</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ข้อมูลส่วนบุคคลของท่านมีความจำเป็น ดังต่อไปนี้
                          </span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <ul>
                          <li>
                            <span>
                              เพื่อการรับผลิตภัณฑ์ใหม่ ผลิตภัณฑ์ทดลอง
                              และการแลกรับของขวัญหรือของรางวัลจากเรา
                            </span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              เพื่อการจองและการรับบริการด้านความงามของเรา (เช่น
                              การแต่งหน้าและการบำรุงเพื่อความงาม)
                            </span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลของบุคคลที่เกี่ยวข้อง</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลที่มีความอ่อนไหว ได้แก่</span>{" "}
                            <span>
                              ข้อมูลศาสนา และ/หรือ หมู่โลหิต
                              ที่ปรากฎบนบัตรประจำตัวประชาชน (ถ้าจำเป็น)
                            </span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <b>
                            <span>หมายเหตุ</span>
                          </b>
                          <span>
                            : หากท่านไม่ให้ข้อมูลส่วนบุคคลใด ๆ
                            ที่จำเป็นสำหรับการรับผลิตภัณฑ์ใหม่
                            และผลิตภัณฑ์ทดลองแก่ท่าน
                            การแลกรับของขวัญและของรางวัล
                            และการจองและการรับบริการด้านความงามของเรา
                            เราอาจไม่สามารถให้บริการแก่ท่าน
                            หรือปฏิบัติตามภาระผูกพันของเราภายใต้สัญญาระหว่างท่านและเรา
                            ไม่ว่าทั้งหมดหรือบางส่วน
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>ประโยชน์โดยชอบด้วยกฎหมาย</span>
                            <span> (</span>
                            <span>
                              สำหรับการประมวลผลข้อมูลส่วนบุคคลของบุคคลผู้เกี่ยวข้องเพื่อการแลกรับของขวัญหรือของรางวัลจากเรา
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ความยินยอม
                              (สำหรับลูกค้าที่ยังไม่เป็นสมาชิกเพื่อติดต่อโดยบริษัทฯ
                              สำหรับรับผลิตภัณฑ์ใหม่ ผลิตภัณฑ์ทดลอง)
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              การปฏิบัติตามสัญญา
                              (สำหรับลูกค้าที่เป็นสมาชิกเพื่อรับผลิตภัณฑ์ใหม่
                              ผลิตภัณฑ์ทดลอง การแลกรับของขวัญหรือของรางวัล
                              การจองและการรับบริการด้านความงาม
                            </span>{" "}
                            <span>
                              และสำหรับลูกค้าที่ยังไม่เป็นสมาชิกสำหรับการจองและการรับบริการด้านความงาม
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ความยินยอมโดยชัดแจ้ง</span>{" "}
                            <span>
                              (สำหรับการประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวเพื่อการแลกรับของขวัญหรือของรางวัล
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              เพื่อเข้าร่วมในแคมเปญ
                              กิจกรรมหรือการประชุมเชิงปฏิบัติการที่จัดขึ้นโดยบริษัทฯ
                            </span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ข้อมูลส่วนบุคคลของท่านมีความจำเป็น ดังต่อไปนี้
                          </span>
                          <span>&nbsp;</span>
                        </p>
                        <ul>
                          <li>
                            <span>
                              เพื่อเชิญชวนลูกค้าเพื่อเข้าร่วมแคมเปญ
                              กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              เพื่อให้ลูกค้าและผู้ที่อาจเข้าร่วมแคมเปญ กิจกรรม
                              หรือประชุมเชิงปฏิบัติการกับลูกค้าลงทะเบียนหรือเข้าร่วมในแคมเปญ
                              กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              เพื่อติดต่อและสื่อสารกับลูกค้าเกี่ยวกับแคมเปญ
                              กิจกรรมหรือการประชุมเชิงปฏิบัติการ เช่น
                              เพื่อการยืนยันการลงทะเบียน
                            </span>{" "}
                            <span>เป็นต้น</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ในขณะเข้าร่วมแคมเปญ
                              กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                              ภาพถ่ายหรือวิดีโอของท่านอาจถูกบันทึกและใช้สำหรับวัตถุประสงค์ภายในบริษัทฯ
                              เช่น
                              เพื่อบันทึกหรือเป็นหลักฐานว่าได้มีการจัดกิจกรรมขึ้นจริง&nbsp;
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            ในขณะเข้าร่วมแคมเปญ
                            กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                            ภาพถ่ายหรือวิดีโอของท่านอาจถูกบันทึกและใช้ในวัตถุประสงค์ทางการตลาด{" "}
                            <span>
                              การประชาสัมพันธ์ และ/หรือ การโฆษณา
                              และอาจเผยแพร่บนสื่อสังคมออนไลน์
                            </span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <ol></ol>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลของบุคคลที่เกี่ยวข้อง</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <b>
                            <span>หมายเหตุ</span>
                          </b>
                          <span>
                            : หากท่านไม่ให้ข้อมูลส่วนบุคคลใด ๆ
                            ที่จำเป็นสำหรับการเชิญชวนท่านเพื่อเข้าร่วมแคมเปญ
                            กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                          </span>{" "}
                          <span>
                            หรือเพื่อให้ท่านลงทะเบียนหรือเข้าร่วมในแคมเปญ
                            กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                            หรือเพื่อติดต่อและสื่อสารกับท่านเกี่ยวกับแคมเปญ
                            กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                            เราอาจไม่สามารถให้ท่านเข้าร่วมในแคมเปญ
                            กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                            หรือปฏิบัติตามภาระผูกพันของเราภายใต้สัญญาระหว่างท่านและเรา
                            ไม่ว่าทั้งหมดหรือบางส่วน
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>ประโยชน์โดยชอบด้วยกฎหมาย </span>
                            <span>(</span>
                            <span>
                              สำหรับการประมวลผลข้อมูลส่วนบุคคลของลูกค้าและบุคคลผู้เกี่ยวข้องสำหรับกิจกรรมหมายเลข
                              1-3 และสำหรับกิจกรรมหมายเลข 4-5 ในบางกรณี
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              การปฏิบัติตามสัญญา
                              (สำหรับการประมวลผลข้อมูลส่วนบุคคลของลูกค้าที่เป็นสมาชิกสำหรับกิจกรรมหมายเลข
                              1-3 สำหรับแคมเปญ
                              กิจกรรมหรือการประชุมเชิงปฏิบัติการที่จัดขึ้นเฉพาะลูกค้าที่เป็นสมาชิกตามเงื่อนไขและข้อกำหนดหรือข้อสัญญา
                              (แล้วแต่กรณี)
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ความยินยอม
                              (สำหรับการประมวลผลข้อมูลส่วนบุคคลของลูกค้าและบุคคลผู้เกี่ยวข้องสำหรับกิจกรรมหมายเลข
                              4-5 ในบางกรณี
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>เพื่อจัดการคำขอ</span>
                          </b>{" "}
                          <b>
                            <span>และ/หรือ</span>
                          </b>{" "}
                          <b>
                            <span>
                              ข้อร้องเรียนของท่านผ่านช่องทางออนไลน์และออฟไลน์
                            </span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <ul>
                          <li>
                            <span>
                              เพื่อจัดการปัญหาและข้อร้องเรียนของลูกค้าเกี่ยวกับการแพ้ผลิตภัณฑ์
                            </span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              เพื่อจัดการคำขอของลูกค้าในการขอเปลี่ยนแปลง/คืนสินค้า/คืนเงิน
                            </span>{" "}
                            <span>(ไม่ใช่กรณีของการแพ้ผลิตภัณฑ์)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            เพื่อจัดการคำขออื่น ๆ ของลูกค้า เช่น
                            ขอเงินคืนกรณีชำระเงินผิดพลาด{" "}
                            <span>
                              การปรับปรุงข้อมูลลูกค้า การสนทนากับลูกค้า
                              หรือการตอบคำถามของลูกค้า
                            </span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <ol></ol>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลด้านการเงิน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการชำระเงิน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการทำงาน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่มีความอ่อนไหว ได้แก่
                              ข้อมูลเกี่ยวกับสุขภาพ{" "}
                            </span>
                            <span>(</span>
                            <span>
                              เช่น อาการ โรค อาการแพ้ รูปภาพของอาการแพ้
                              ใบรับรองแพทย์ การรักษาพยาบาล
                            </span>
                            <span>) </span>
                            <span>
                              เพื่อจัดการข้อร้องเรียนเกี่ยวกับการแพ้ผลิตภัณฑ์
                            </span>{" "}
                            <span>และข้อมูลศาสนา และ/หรือ หมู่โลหิต</span>{" "}
                            <span>
                              (ถ้าจำเป็น)
                              ที่ปรากฎบนบัตรประจำตัวประชาชนเพื่อปรับปรุงข้อมูลของลูกค้า
                            </span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <b>
                            <span>หมายเหตุ</span>
                          </b>
                          <span>
                            : หากท่านไม่ให้ข้อมูลส่วนบุคคลใด ๆ
                            ที่จำเป็นสำหรับการจัดการคำขอ
                          </span>{" "}
                          <span>และ/หรือ</span>{" "}
                          <span>
                            ข้อร้องเรียนเกี่ยวกับการแพ้ผลิตภัณฑ์ของท่าน
                            เราอาจไม่สามารถแก้ไขข้อกังวลและคำขอของท่านได้
                            และเราอาจไม่สามารถปฏิบัติตามกฎหมายซึ่งอาจส่งผลให้เกิดการฝ่าฝืนกฎหมายที่ใช้บังคับกับเรา
                            นอกจากนี้ หากท่านไม่ให้ข้อมูลส่วนบุคคลใด ๆ
                            ที่จำเป็นสำหรับเพื่อจัดการคำขอของลูกค้าในการขอเปลี่ยนแปลง/คืนสินค้า/คืนเงิน
                            การขอเงินคืนกรณีชำระเงินผิดพลาด
                            เราอาจไม่สามารถให้บริการแก่ท่าน
                            หรือปฏิบัติตามภาระผูกพันของเราภายใต้สัญญาระหว่างท่านและเรา
                            ไม่ว่าทั้งหมดหรือบางส่วน
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ประโยชน์โดยชอบด้วยกฎหมาย (เพื่อจัดการคำขออื่น ๆ
                            </span>{" "}
                            <span>
                              ได้แก่ การปรับปรุงข้อมูลลูกค้า การสนทนากับลูกค้า
                              หรือการตอบคำถามของลูกค้า
                            </span>
                            <span>)&nbsp;</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              การปฏิบัติตามกฎหมาย
                              (เพื่อจัดการข้อร้องเรียนเกี่ยวกับการแพ้ผลิตภัณฑ์
                            </span>{" "}
                            <span>
                              รวมถึงการประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                              ได้แก่ อาการ โรค และอาการแพ้
                            </span>{" "}
                            <span>
                              เป็นต้น
                              เป็นไปตามกฎหมายเกี่ยวข้องกับเหตุการณ์ไม่พึงประสงค์จากการใช้เครื่องสำอาง
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              การปฏิบัติตามสัญญา
                              (สำหรับขอเงินคืนกรณีการชำระเงินผิดพลาด
                            </span>{" "}
                            <span>และการเปลี่ยนผลิตภัณฑ์/คืน/คืนเงิน)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ความยินยอมโดยชัดแจ้ง</span>{" "}
                            <span>
                              (สำหรับการประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                            </span>{" "}
                            <span>
                              ได้แก่ รูปภาพอาการแพ้ ใบรับรองแพทย์
                              การรักษาพยาบาลเพื่อจัดการข้อร้องเรียนเกี่ยวกับการแพ้ผลิตภัณฑ์
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              เพื่อบันทึกประวัติการซื้อสินค้าของท่านและการขายสินค้าประจำวันของเรา
                            </span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                        <span>&nbsp;</span>
                        <br />
                        <ul></ul>
                      </td>
                      <td style={cellStyle1}>
                        <p>
                          <span>ประโยชน์โดยชอบด้วยกฎหมาย</span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              เพื่อทำการวิเคราะห์ข้อมูลและสำหรับการส่งข้อมูลหรือเนื้อหาเกี่ยวกับการตลาด
                              เช่น ข้อความส่งเสริมการขาย
                            </span>
                          </b>
                          <b>
                            <span>&nbsp; </span>
                          </b>
                          <b>
                            <span>แคมเปญส่งเสริมการตลาด</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลเกี่ยวกับอุปกรณ์และการเข้าถึงข้อมูล
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>ประโยชน์โดยชอบด้วยกฎหมาย</span>
                            <span> (</span>
                            <span>
                              สำหรับลูกค้าที่ยังไม่ได้มีการสอบถามเพื่อให้ความยินยอมเพื่อทำการวิเคราะห์ข้อมูลและสำหรับการส่งข้อมูลหรือเนื้อหาเกี่ยวกับการตลาด
                              เช่น ข้อความส่งเสริมการขาย&nbsp;
                              แคมเปญส่งเสริมการตลาดซึ่งบริษัทฯ
                              ได้มีการกำหนดช่องทางสำหรับปฏิเสธข้อมูลให้ลูกค้าดังกล่าว
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ความยินยอม</span>
                            <span> (</span>
                            <span>
                              เพื่อทำการวิเคราะห์ข้อมูลและสำหรับการส่งข้อมูลหรือเนื้อหาเกี่ยวกับการตลาด
                              เช่น ข้อความส่งเสริมการขาย&nbsp;
                              แคมเปญส่งเสริมการตลาด
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>เพื่อปฏิบัติตามกฎหมายที่ใช้บังคับ</span>
                          </b>
                          <span>&nbsp;</span>
                        </p>
                        <ul>
                          <li>
                            <span>บริษัทฯ</span>{" "}
                            <span>
                              อาจจำเป็นต้องประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อการปฏิบัติตามกฎหมาย
                              กฎเกณฑ์ คำสั่ง ประกาศ หรือข้อกำหนดอื่น ๆ
                              ที่ออกโดยหน่วยงานที่เกี่ยวข้อง
                            </span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              เพื่อดำเนินการยื่นข้อมูลหรือเอกสารที่จำเป็น
                              หรือการจัดการเพื่อปฏิบัติตามภาระทางภาษีของเรา
                              รวมถึงการยื่นเอกสารที่จำเป็นต่อกรมสรรพากร
                              รวมถึงแต่ไม่จำกัดเพียงการดำเนินการขอคืนภาษีมูลค่าเพิ่มและการออกใบแจ้งหนี้
                              และ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            การปฏิบัติตามกฎหมาย
                            <span>&nbsp;&nbsp;&nbsp; </span>
                            <span>
                              อื่น ๆ เช่น
                              กฎหมายที่เกี่ยวข้องกับเหตุการณ์ไม่พึงประสงค์จากการใช้เครื่องสำอางภายใต้การดูแลของกระทรวงสาธารณสุข
                            </span>
                            <span> (</span>
                            <span>เช่นพระราชบัญญัติเครื่องสำอาง พ.ศ.</span>
                            <span>2558) </span>
                            <span>
                              กฎหมายคุ้มครองข้อมูลส่วนบุคคล
                              โดยการดำเนินการตามคำขอของท่านเพื่อใช้สิทธิในส่วนที่เกี่ยวกับข้อมูลส่วนบุคคลของท่าน
                            </span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <ol></ol>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลเกี่ยวกับอุปกรณ์และการเข้าถึงข้อมูล
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลของบุคคลที่เกี่ยวข้อง</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลด้านการเงิน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการชำระเงิน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการทำงาน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่มีความอ่อนไหว ได้แก่
                              ข้อมูลเกี่ยวกับสุขภาพ (เช่น อาการ โรค อาการแพ้
                              รูปภาพของอาการแพ้ ใบรับรองแพทย์ การรักษาพยาบาล
                              เพื่อจัดการข้อร้องเรียนเกี่ยวกับการแพ้ผลิตภัณฑ์
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลอื่นใดตามที่กฎหมายกำหนด</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ประเภทของข้อมูลส่วนบุคคลที่ถูกประมวลผลสำหรับวัตถุประสงค์นี้จะขึ้นอยู่กับแต่ละกรณี
                            ตามที่กฎหมายกำหนด
                          </span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <b>
                            <span>หมายเหตุ</span>
                          </b>
                          <span>
                            : หากท่านไม่ให้ข้อมูลส่วนบุคคลใด ๆ
                            ที่จำเป็นสำหรับการปฏิบัติตามกฎหมายซึ่งใช้บังคับแก่บริษัทฯ
                            เราและ/หรือ
                          </span>{" "}
                          <span>
                            ท่านอาจไม่สามารถปฏิบัติตามกฎหมาย
                            และอาจส่งผลต่อการประมวลผลข้อมูลส่วนบุคคลของท่านที่จำเป็น
                            รวมถึงอาจส่งผลให้เกิดการฝ่าฝืนกฎหมายที่ใช้บังคับกับเรา
                          </span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>การปฏิบัติตามกฎหมาย</span>
                            <span> (</span>
                            <span>สำหรับกิจกรรมหมายเลข </span>
                            <span>1 </span>
                            <span>และ </span>
                            <span>2 </span>
                            <span>
                              และสำหรับการประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวเพื่อการปฏิบัติตามกฎหมายที่เกี่ยวข้องกับเหตุการณ์ไม่พึงประสงค์จากการใช้เครื่องสำอาง
                            </span>{" "}
                            <span>ได้แก่ อาการ โรค อาการแพ้</span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ความยินยอมโดยชัดแจ้ง</span>{" "}
                            <span>
                              (สำหรับการประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                            </span>{" "}
                            <span>
                              เพื่อการปฏิบัติตามกฎหมายที่เกี่ยวข้องกับเหตุการณ์ไม่พึงประสงค์จากการใช้เครื่องสำอาง
                              ได้แก่
                            </span>{" "}
                            <span>
                              รูปภาพของอาการแพ้ ใบรับรองแพทย์ การรักษาพยาบาล
                            </span>
                            <span>)</span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <b>
                            <span>
                              เพื่อก่อ ใช้ ปฏิบัติตาม
                              หรือแก้ต่างข้อเรียกร้องทางกฎหมาย
                            </span>
                          </b>
                          <span>&nbsp;</span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ข้อมูลส่วนบุคคลของท่านอาจถูกประมวลผลเพื่อใช้เป็นส่วนหนึ่งในการก่อ
                            การใช้ การปฏิบัติตาม
                            หรือการแก้ต่างข้อเรียกร้องทางกฎหมาย
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลเกี่ยวกับอุปกรณ์และการเข้าถึงข้อมูล
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ข้อมูลส่วนบุคคลที่ระบุตัวตนและข้อมูลการติดต่อ
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลด้านการเงิน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการชำระเงิน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลการทำงาน</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลของบุคคลที่เกี่ยวข้อง</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ข้อมูลส่วนบุคคลที่มีความอ่อนไหว (ถ้ามี)</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              ข้อมูลที่เกี่ยวข้องกับข้อเรียกร้องทางกฎหมาย
                            </span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ประเภทของข้อมูลส่วนบุคคลที่ถูกประมวลผลสำหรับวัตถุประสงค์นี้จะขึ้นอยู่กับแต่ละกรณี&nbsp;
                          </span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>ประโยชน์โดยชอบด้วยกฎหมาย</span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>
                              การก่อตั้งสิทธิเรียกร้องตามกฎหมาย
                              (สำหรับข้อมูลส่วนบุคคลที่มีความอ่อนไหว)
                            </span>
                            <span>&nbsp;</span>
                          </li>
                        </ul>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                        <p>
                          <span>คุกกี้</span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ข้อมูลส่วนบุคคลของท่านอาจถูกประมวลผลเพื่อเพิ่มประสบการณ์การเยี่ยมชมและการใช้เว็บไซต์ของเรา
                            ให้การเยี่ยมชมเว็บไซต์ของเรามีความน่าสนใจมากขึ้น
                            และเพื่อให้สามารถใช้งานฟังก์ชันบางอย่างได้
                            เราจึงใช้สิ่งที่เรียกว่า
                          </span>{" "}
                          <b>
                            <span>คุกกี้</span>
                          </b>
                          <span>”</span>
                          <span> ในหน้าต่าง ๆ ของเว็บไซต์</span>
                          <span>&nbsp;</span>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>&nbsp;</span>
                        </p>
                        <p>
                          <span>
                            ท่านสามารถดูข้อมูลเพิ่มเติมของคุกกี้ที่เราใช้ ใน
                          </span>
                          <b>
                            <span>หัวข้อที่ </span>
                          </b>
                          <b>
                            <span>6</span>
                          </b>
                          <b>
                            <span>. เรื่องคุกกี้</span>
                          </b>{" "}
                          <span>ด้านล่าง</span>
                          <span>&nbsp;</span>
                        </p>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li >
                            <span>
                              ข้อมูลส่วนบุคคลเกี่ยวกับอุปกรณ์และการเข้าถึงข้อมูล
                            </span>
                            <span >
                              &nbsp;
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td style={cellStyle1}>
                        <ul>
                          <li>
                            <span>
                              ประโยชน์โดยชอบด้วยกฎหมาย (สำหรับคุกกี้ที่จำเป็น)
                            </span>
                            <span>&nbsp;</span>
                          </li>
                          <li>
                            <span>ความยินยอม</span>
                            <span> (</span>
                            <span>สำหรับคุกกี้เพื่อการใช้งาน</span>{" "}
                            <span>
                              และคุกกี้ที่ปรับตามความต้องการส่วนตัวและการโฆษณา
                            </span>
                            <span>)</span>
                            <span >
                              &nbsp;
                            </span>
                          </li>
                        </ul>
                        <p>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                        <p>
                          <b>
                            <span>หมายเหตุ</span>
                          </b>
                          <span>:</span>
                          <span>
                            {" "}
                            ท่านสามารถปรับและตั้งค่าการใช้งานคุกกี้ได้ตามความต้องการของท่านบนเว็บไซต์ของเรา
                          </span>
                          <span >
                            &nbsp;
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  ในกรณีที่เราจะทำการประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์อื่นใดนอกเหนือจากที่ระบุในนโยบายฯ
                  ฉบับนี้
                </span>{" "}
                <span>
                  ซึ่งได้แจ้งแก่ท่านแล้วนั้น
                  เราจะทำการแจ้งท่านถึงการประมวลผลข้อมูลส่วนบุคคลที่เพิ่มเติมนั้น
                  และ/หรือ
                </span>{" "}
                <span>
                  จัดให้มีการขอความยินยอมจากท่าน
                  หากกฎหมายที่ใช้บังคับได้กำหนดไว้
                </span>
                <span >
                  &nbsp;
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>คุกกี้</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  โปรดทราบว่าคุกกี้เป็นชุดข้อมูลในรูปแบบอักษรขนาดเล็ก
                  ซึ่งถูกจัดเก็บอยู่ในอุปกรณ์ของท่าน
                  คุกกี้บางประเภทที่เราใช้งานจะถูกลบทิ้งภายหลังจบการใช้งานเบราว์เซอร์
                  กล่าวคือ ภายหลังจากที่ท่านปิดหน้าเบราว์เซอร์
                </span>
                <span> (</span>
                <span>ซึ่งเรียกกันว่า </span>
                <span>Session Cookies</span>
                <span>
                  ) โดยคุกกี้ประเภทอื่น ๆ
                  อาจยังคงอยู่ในอุปกรณ์ของท่านและอนุญาตให้เราหรือบริษัทในเครือของเราจดจำการเข้าเบราว์เซอร์ของท่านในครั้งถัดไป
                  (ซึ่งเรียกกันว่า{" "}
                </span>
                <span>Permanent Cookies)&nbsp;</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  ข้อมูลเกี่ยวกับทางเลือกของท่านและรายละเอียดของคุกกี้ที่เราใช้งานได้ระบุไว้ด้านล่าง
                  ดังต่อไปนี้
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <span>คุกกี้ที่จำเป็น (</span>
                  <span>Required Cookies)</span>
                  <span >
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  คุกกี้เหล่านี้มีความจำเป็นในการใช้งานเพื่อนำไปสู่การใช้เว็บไซต์ของเราและการใช้งานคุณสมบัติเฉพาะ&nbsp;&nbsp;
                  หากท่านใช้งานโดยปราศจากคุกกี้
                  เราไม่สามารถรับรองการทำงานอย่างเป็นปกติของเว็บไซต์ (เช่น
                  การพิมพ์ข้อความ) ในระหว่างการเข้าใช้งานหน้าต่าง ๆ
                  ของเว็บไซต์ได้ ทั้งนี้
                  คุกกี้ถูกใช้งานเพื่อเก็บรวบรวมข้อมูลเกี่ยวกับการปฏิบัติการของเว็บไซต์ของเรา
                  เช่น
                  เพื่อให้บริการเนื้อหาส่วนหลักและจัดการข้อขัดข้องของเว็บไซต์
                </span>{" "}
                <span>
                  เรายังใช้คุกกี้เพื่อเก็บข้อมูลของท่านหรือเพื่อเก็บข้อมูลที่ท่านกรอกเป็นการชั่วคราว
                  ตราบเท่าที่จำเป็น
                </span>
                <span >
                  &nbsp;
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <span>คุกกี้เพื่อการใช้งาน (</span>
                  <span>Functional Cookies)</span>
                  <span >
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  คุกกี้เหล่านี้เก็บข้อมูลนามแฝงและไม่สามารถติดตามการเคลื่อนไหวบนเว็บไซต์อื่นได้
                  เราใช้คุกกี้ดังกล่าวเพื่อการวิเคราะห์การใช้งานโดยนามแฝง
                  คุกกี้ยังช่วยให้เว็บไซต์ของเราจดจำทางเลือกต่าง ๆ ของท่าน เช่น
                  ภาษาหรือภูมิภาค
                  เพื่อให้ท่านสามารถใช้งานคุณสมบัติเฉพาะที่พัฒนาแล้ว
                </span>
                <span >
                  &nbsp;
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <span>คุกกี้ที่ปรับตามความต้องการส่วนตัวและการโฆษณา </span>
                  <span>(Personalization and Advertising Cookies)</span>
                  <span >
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  คุกกี้เหล่านี้ถูกใช้เพื่อการโฆษณาที่เฉพาะเจาะจงซึ่งมีความเกี่ยวข้องต่อผู้ใช้และถูกปรับให้เข้ากับความสนใจของผู้ใช้
                </span>
                <span>&nbsp;</span>
                <span >
                  &nbsp;
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  ท่านสามารถตั้งค่าเบราว์เซอร์เพื่อให้ท่านได้รับทราบเกี่ยวกับการตั้งค่าของคุกกี้
                  และตัดสินใจได้ด้วยตัวเองในการยอมรับหรือปฏิเสธการใช้งานของคุกกี้เป็นการเฉพาะหรือเป็นการทั่วไปได้ตลอดเวลา
                </span>{" "}
                <span>
                  โดยการตั้งค่าบนเว็บไซต์ที่ท่านใช้งานตามความต้องการของท่านได้
                  ทั้งนี้
                  การไม่ยอมรับการใช้งานคุกกี้อาจเป็นการจำกัดการทำงานของเว็บไซต์ของเรา
                  หรือทำให้เว็บไซต์สามารถทำงานได้ไม่เต็มประสิทธิภาพ
                </span>{" "}
                <span>นอกจากนี้ </span>
                <span>
                  ท่านสามารถติดตั้งส่วนเสริมบนเบราว์เซอร์ของท่านเพื่อระงับคุกกี้ที่ไม่จำเป็น
                  ซึ่งจะทำให้ท่านไม่สามารถมองเห็นโฆษณาที่แสดงตามความสนใจของท่าน&nbsp;
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  นโยบายฯ ฉบับนี้ ครอบคลุมถึงการที่เราใช้งานคุกกี้
                  แต่จะไม่ครอบคลุมการใช้คุกกี้โดยผู้โฆษณารายอื่น
                  หรือเว็บไซต์ของบุคคลอื่นที่อาจนำมาสู่เว็บไซต์ของเรา
                  หากท่านเข้าไปสู่หรือถูกนำไปสู่เว็บไซต์ของบุคคลอื่น
                  เราขอเสนอให้ท่านอ่านนโยบายความเป็นส่วนตัวและนโยบายคุกกี้ของเว็บไซต์ดังกล่าว
                </span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>การเปิดเผยข้อมูลส่วนบุคคลของท่าน</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  ในการประมวลผลข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ซึ่งได้กล่าวมาข้างต้นนั้น
                  เราอาจจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่านแก่บุคคลภายนอก
                  ดังต่อไปนี้
                </span>{" "}
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <span>&nbsp;</span>
                  แก่บุคคลภายนอกซึ่งเป็นผู้จำหน่าย ผู้จัดหาสินค้า
                  หรือผู้ให้บริการอื่นซึ่งให้บริการแก่บริษัทฯ (ประเทศไทย) เช่น
                  บริษัทที่ได้รับการว่าจ้างในการจัดการระบบและคลังข้อมูล
                  ตัวแทนรับชำระเงินทางอิเล็กทรอนิกส์ ผู้ให้บริการจัดส่งสินค้า
                  บริษัทด้านการตลาดและการวิเคราะห์ข้อมูล (ประเทศไทย) เป็นต้น
                  เพื่อสร้างและลงทะเบียนบัญชีลูกค้า
                  เพื่อดำเนินการชำระเงินค่าสินค้า
                  เพื่อดำเนินการส่งสินค้าที่ท่านสั่งซื้อให้ท่านหรือบุคคลผู้เกี่ยวข้อง
                  เพื่อส่งการ์ดอวยพรไปยังผู้รับของขวัญ
                  เพื่อให้ลูกค้าหรือผู้ที่อาจเข้าร่วมแคมเปญ กิจกรรม
                  หรือประชุมเชิงปฏิบัติการกับลูกค้าลงทะเบียนหรือเข้าร่วมในแคมเปญ
                  กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                  เพื่อจัดการปัญหาและข้อร้องเรียนเกี่ยวกับการแพ้ผลิตภัณฑ์
                  เพื่อจัดการคำขอในการขอเปลี่ยนแปลง/คืนสินค้า/คืนเงิน
                  (ไม่ใช่กรณีของการแพ้ผลิตภัณฑ์)
                  และเพื่อทำการวิเคราะห์ข้อมูลและสำหรับการส่งข้อมูลหรือเนื้อหาเกี่ยวกับการตลาด
                  เช่น ข้อความส่งเสริมการขาย แคมเปญส่งเสริมการตลาด
                </li>
                <li>
                  <span>แก่ที่</span>
                  <span>
                    ปรึกษากฎหมายภายนอก
                    ในกรณีที่เกิดข้อพิพาทและการดำเนินคดีทางกฎหมายเพื่อก่อ ใช้
                    ปฏิบัติตาม หรือแก้ต่างข้อเรียกร้องทางกฎหมาย
                  </span>
                  <span >
                    &nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    แก่เจ้าหน้าที่ที่มีอำนาจ
                    เจ้าพนักงานภาษีอากรหรือเจ้าพนักงานทางภาครัฐ
                    ศาลในเขตที่มีอำนาจ ซึ่งรวมถึงแต่ไม่จำกัดเพียงกรมสรรพากร
                    สำนักงานคณะกรรมการอาหารและยา กระทรวงสาธารณสุข
                  </span>{" "}
                  <span>
                    เพื่อดำเนินการยื่นข้อมูลหรือเอกสารที่จำเป็น
                    หรือการจัดการเพื่อปฏิบัติตามภาระทางภาษี
                    รวมถึงการยื่นเอกสารทางภาษีที่จำเป็นต่อกรมสรรพากร
                    และการปฏิบัติตามกฎหมายอื่น ๆ เช่น
                    กฎหมายคุ้มครองข้อมูลส่วนบุคคล
                    กฎหมายที่เกี่ยวข้องกับเหตุการณ์ไม่พึงประสงค์จากการใช้เครื่องสำอาง
                  </span>{" "}
                  <span>
                    และเพื่อก่อ ใช้ ปฏิบัติตาม หรือแก้ต่างข้อเรียกร้องทางกฎหมาย
                  </span>
                  <span >
                    &nbsp;
                  </span>
                </li>
                <li>
                  <span>แก่บุคคลหรือหน่วยงานใดที่บริษัทฯ</span>{" "}
                  <span>
                    มีความจำเป็นตามกฎหมายที่จะต้องเปิดเผยข้อมูลส่วนบุคคลของท่าน
                    หรือที่บริษัทฯ
                  </span>{" "}
                  <span>
                    ได้รับอนุญาตจากท่านหรือองค์กรของท่านให้เปิดเผยข้อมูลส่วนบุคคล
                    และ/หรือ
                  </span>
                  <span>&nbsp;</span>
                  <span >
                    &nbsp;
                  </span>
                </li>
                <li>
                  <span>แก่ผู้</span>
                  <span>
                    ที่จะเข้าซื้อกิจการของบริษัทฯ
                    ในกรณีของการควบรวมหรือการซื้อขายของบริษัทฯ
                  </span>
                  <span >
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ol></ol>
              <ol></ol>
              <ol></ol>
              <ol></ol>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>การถ่ายโอนข้อมูลส่วนบุคคลระหว่างประเทศ</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>โดยปกติ บริษัทฯ</span>{" "}
                <span>ทำการโอน เปิดเผย และอนุญาตบุคคลภายนอก</span>{" "}
                <span>ในบางกรณี</span>{" "}
                <span>(ประเทศออสเตรเลียและสิงคโปร์)&nbsp;</span>
                <span>
                  เข้าถึงข้อมูลส่วนบุคคลของท่าน
                  เพื่อการประมวลผลข้อมูลส่วนบุคคลตามวัตถุประสงค์ที่ระบุอยู่ในนโยบายฯ
                  ฉบับนี้ เช่น เพื่อสร้างและลงทะเบียนบัญชีลูกค้า
                  เพื่อดำเนินการชำระเงินค่าสินค้า
                  เพื่อดำเนินการส่งสินค้าที่ท่านสั่งซื้อให้ท่านหรือบุคคลผู้เกี่ยวข้อง
                  เพื่อส่งการ์ดอวยพรไปยังผู้รับของขวัญ
                </span>{" "}
                <span>
                  เพื่อให้ลูกค้าหรือผู้ที่อาจเข้าร่วมแคมเปญ กิจกรรม
                  หรือประชุมเชิงปฏิบัติการกับลูกค้าลงทะเบียนหรือเข้าร่วมในแคมเปญ
                  กิจกรรมหรือการประชุมเชิงปฏิบัติการ
                </span>{" "}
                <span>
                  เพื่อจัดการคำขอในการขอเปลี่ยนแปลง/คืนสินค้า/คืนเงิน
                  (ไม่ใช่กรณีของการแพ้ผลิตภัณฑ์)
                  เพื่อทำการวิเคราะห์ข้อมูลและสำหรับการส่งข้อมูลหรือเนื้อหาเกี่ยวกับการตลาด
                  เช่น ข้อความส่งเสริมการขาย แคมเปญส่งเสริมการตลาดเป็นต้น
                </span>{" "}
                <span>
                  ทั้งนี้
                  ประเทศปลายทางที่รับข้อมูลส่วนบุคคลดังกล่าวนั้นอาจมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลซึ่งแตกต่างจากที่ได้กำหนดไว้โดยหน่วยงานคุ้มครองข้อมูลส่วนบุคคลในประเทศไทย
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>อย่างไรก็ตาม บริษัทฯ</span>{" "}
                <span>จะดำเนินการให้แน่ใจได้ว่า</span>{" "}
                <span>
                  ข้อมูลส่วนบุคคลของท่านได้รับการปกป้องคุ้มครอง
                  โดยการจัดให้มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอในการโอนข้อมูลส่วนบุคคลใด
                  ๆ ของท่านออกนอกประเทศไทย
                  ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลได้กำหนดไว้
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>บริษัทฯ</span>{" "}
                <span>
                  จะเก็บรักษาข้อมูลส่วนบุคคลของท่านและข้อมูลส่วนบุคคลของบุคคลผู้เกี่ยวข้องไว้ตราบเท่าที่มีความจำเป็นต่อการปฏิบัติตามภาระผูกพันภายใต้สัญญาของเรากับท่าน
                  ซึ่งโดยทั่วไป บริษัทฯ
                  จะเก็บรักษาข้อมูลส่วนบุคคลของท่านตลอดระยะเวลาความสัมพันธ์ตามสัญญาและเก็บรักษาเป็นระยะเวลาต่อไปอีกไม่เกินกว่า
                </span>
                <span>
                  {" "}
                  10 (สิบ) ปี หลังจากความสัมพันธ์ตามสัญญาสิ้นสุดลง
                  หรือหลังจากที่ท่านได้มีการติดต่อสื่อสารกับ
                </span>
                <span>บริษัทฯ</span> <span>เป็นครั้งสุดท้าย</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  เราจะเก็บรักษาข้อมูลส่วนบุคคลของบุคคลผู้เกี่ยวข้องเป็นระยะเวลาไม่เกินกว่าระยะเวลาที่เราจะเก็บรักษาข้อมูลส่วนบุคคลของลูกค้าซึ่งเป็นผู้เกี่ยวข้องกับบุคคลผู้เกี่ยวข้อง
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  อย่างไรก็ตาม
                  เราอาจเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลานานกว่าระยะเวลาที่ระบุข้างต้น{" "}
                </span>
                <span>
                  &nbsp;
                  <br />
                </span>
                <span>เมื่อกฎหมายที่ใช้บังคับจะอนุญาตหรือกำหนดให้กระทำได้</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>สิทธิในข้อมูลส่วนบุคคลของท่าน</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span >
                  &nbsp;
                </span>
              </p>
              <p>
                <span>
                  ท่านมีสิทธิเกี่ยวกับข้อมูลส่วนบุคคล
                  ซึ่งเป็นไปตามเงื่อนไขและข้อจำกัดภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล
                  ดังต่อไปนี้
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span >
                  &nbsp;
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    ขอถอนความยินยอมของท่าน
                    หรือขอเปลี่ยนแปลงขอบเขตการให้ความยินยอมของท่าน
                  </span>
                  <span>&nbsp;</span>
                </li>
                <li>
                  <span>
                    ขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลของท่านซึ่งอยู่ในความรับผิดชอบของบริษัทฯ
                    และร้องขอให้ทำการเปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลของท่าน
                    ในกรณีที่ท่านไม่ได้ให้ความยินยอม
                  </span>
                  <span>&nbsp;</span>
                </li>
                <li>
                  <span>
                    ขอให้ดำเนินการให้ข้อมูลส่วนบุคคลของท่านถูกต้อง
                    หรือเป็นปัจจุบัน
                  </span>
                  <span>&nbsp;</span>
                </li>
                <li>
                  <span>
                    ในบางกรณี ท่านอาจขอให้ดำเนินการลบ ทำลาย
                    หรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้
                  </span>
                  <span>&nbsp;</span>
                </li>
                <li>
                  <span>
                    ในบางกรณี ท่านอาจขอคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่าน
                  </span>
                  <span>&nbsp;</span>
                </li>
                <li>
                  <span>
                    ในบางกรณี ท่านอาจขอรับข้อมูลส่วนบุคคลของท่านที่บริษัทฯ
                    ได้ทำให้ข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
                    รวมทั้งมีสิทธิขอให้บริษัทฯ
                    ส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นได้ตามที่กฎหมายกำหนด
                    และ
                  </span>
                  <span>&nbsp;</span>
                </li>
                <li>
                  <span>
                    ในบางกรณี ท่านอาจขอให้ระงับการประมวลผลข้อมูลส่วนบุคคลของท่าน
                  </span>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  เราขอสงวนสิทธิที่จะไม่ปฏิบัติตามคำขอของท่าน
                  หากการไม่ปฏิบัติตามคำขอนี้มีความเหมาะสมและได้รับอนุญาตให้กระทำได้โดยกฎหมายที่ใช้บังคับ
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  นอกจากสิทธิที่กล่าวมาข้างต้น
                  ท่านยังมีสิทธิยื่นเรื่องร้องเรียนเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่านกับคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลตามขั้นตอนที่กำหนดไว้ในกฎหมายคุ้มครองข้อมูลส่วนบุคคลอย่างไรก็ตาม
                  เราแนะนำให้ท่านติดต่อเราเป็นลำดับแรกในกรณีที่มีข้อกังวลใด ๆ
                  เพื่อให้เราสามารถแก้ไขปัญหาของท่านได้อย่างมีประสิทธิภาพในเวลาที่เหมาะสม
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  ตราบเท่าที่กฎหมายและกฎเกณฑ์ที่บังคับใช้อนุญาตให้กระทำได้
                  เราอาจมีสิทธิเรียกเก็บค่าใช้จ่ายอันสมเหตุผลที่อาจะเกิดขึ้นจากการดำเนินการจัดการตามคำขอใด
                  ๆ ที่ระบุไว้ข้างต้น
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>ข้อมูลการติดต่อ</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  หากท่านมีข้อสงสัยใด ๆ อันเกี่ยวกับข้อมูลส่วนบุคคลของท่าน
                  หรือท่านประสงค์จะใช้สิทธิใด ๆ ในข้อมูลส่วนบุคคลของท่าน
                  กรุณาติดต่อเราที่
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <strong>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</strong>
                <strong>: </strong>
                <strong>บริษัท</strong> <strong>ติลลิกีแอนด์กิบบินส์</strong>{" "}
                <strong>ดิจิทัล</strong> <strong>โซลูชั่นส์</strong>{" "}
                <strong>จำกัด</strong>
              </p>
              <p>&nbsp;</p>
              <div className="TableWrapper">
                <table width="702">
                  <tbody>
                    <tr>
                      <td width="132">
                        <p>
                          <strong>ที่อยู่</strong>
                        </p>
                      </td>
                      <td width="570">
                        <p>
                          อาคารศุภาลัย แกรนด์ ทาวเวอร์ ชั้น 20-26 เลขที่ 1011
                          ถนนพระราม 3 แขวงช่องนนทรี เขตยานนาวา
                          จังหวัดกรุงเทพมหานคร 10120 ประเทศไทย
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="132">
                        <p>
                          <strong>ที่อยู่อีเมล</strong>
                          <strong>:</strong>
                        </p>
                      </td>
                      <td width="570">
                        <p>
                          <a href="mailto:digital@tilleke.com">
                            digital@tilleke.com
                          </a>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <b>
                    <span>การเปลี่ยนแปลงนโยบายฯ ฉบับนี้</span>
                  </b>
                  <span>&nbsp;</span>
                </li>
              </ul>
              <ol></ol>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>บริษัทฯ</span>{" "}
                <span>
                  อาจทำการเปลี่ยนแปลง ปรับเปลี่ยน หรือปรับปรุงนโยบายฯ ฉบับนี้
                </span>{" "}
                <span>เป็นครั้งคราวเมื่อจำเป็น โดยบริษัทฯ</span>{" "}
                <span>
                  จะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงใด ๆ
                  ดังกล่าวผ่านทางหน้าเว็บไซต์ของเรา{" "}
                </span>
                <span>
                  &nbsp;
                  <br />
                </span>
                <span>หรือโดยการส่งการแจ้งเตือนไปยังอีเมลของท่าน หรือ </span>
                <span>LINE account</span>
                <span> ของท่านผ่าน</span>
                <span> LINE Official Account</span>
                <span>
                  {" "}
                  ของเรา หรือติดต่อผ่านช่องทางที่เห็นสมควรตามความเหมาะสม
                  อย่างไรก็ตาม เราแนะนำให้ท่านทบทวนนโยบายฯ
                  ฉบับนี้อย่างสม่ำเสมอเพื่อรับทราบเกี่ยวกับการเปลี่ยนแปลงใดๆ
                </span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  ทั้งนี้ ในกรณีที่การเปลี่ยนแปลง การปรับเปลี่ยน
                  หรือการปรับปรุงใด ๆ
                  นั้นกระทบต่อวัตถุประสงค์ที่เคยได้รับความยินยอมจากท่านก่อนหน้านี้
                  บริษัทฯ จะทำการแจ้งท่านถึงการเปลี่ยนแปลงดังกล่าว
                  รวมถึงจะทำการขอความยินยอมจากท่าน
                  (หากกฎหมายกำหนดให้ต้องทำเช่นนั้น)
                  ก่อนการเปลี่ยนแปลงดังกล่าวจะมีผลใช้บังคับ
                </span>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>นโยบายฯ ฉบับนี้มีผลใช้บังคับตั้งแต่วันที่ </span>
                <span>26 </span>
                <span>กันยายน พ.ศ. </span>
                <span>2566 </span>
                <span>เป็นต้นไป</span>
                <span>&nbsp;</span>
              </p>
            </div>
          </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              backgroundColor: "#000",
              borderColor: "#000",
              color: "#FFF",
              borderRadius: "1px",
            }}
            onClick={handleConsent}
          >
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>
    </label>
  );
}

export default ConsentLink;
