import React, { useState, useRef, useEffect } from "react";
import { Button, Container, Alert,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./RegistrationForm.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import eventImage from "./images/Main.jpg";

const CancelPageForeverCentralWorld = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [store, setStore] = React.useState("");
  const [date, setDate] = React.useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [dupCancelModalOpen, setDupCancelModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateFormat, setDateFormat] = React.useState("");
  const [timeFormat, setTimeFormat] = React.useState("");
  const [resultByUUID, setResultByUUID] = React.useState("");

  const handleUpdate = async () => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    const uuid = params.get("p1");

    if (!uuid || uuid.length !== 8) {
      // Handle the case where uuid is not present
      console.error("UUID is missing");
      setError("UUID is missing or invalid.");
      return;
    }

    try {
      const response = await axios.put(`${apiUrl}/checkUUIDBeforeEdit`, {
        uuid,
      },{
        headers: {
          "Content-Type": "application/json",
        },
      crossdomain: true
    });
      // console.log(response.data.message);
      console.log(resultByUUID);

      if (response.status === 200) {
        if (response.data.message === "Found records.") {
          navigate('/Forever/CentralWorld', {
            state: {
              firstName: resultByUUID.firstName,
              lastName: resultByUUID.lastName,
              mobilePhone: resultByUUID.mobilePhone,
              email: resultByUUID.email,
              uuid: resultByUUID.uuid,
              oldslotid: resultByUUID.oldslotid,
              odjDateTime: resultByUUID.odjDateTime,
              optinSmsMms: resultByUUID.optinSmsMms,
              optinEdm: resultByUUID.optinEdm,
              consentTandc: resultByUUID.consentTandc,
            }
          });
        }else{
          setDupCancelModalOpen(true);
        }
      }
    } catch (error) {
      setErrorModalOpen(true); // Open the error modal
      console.error("API error:", error); // Optional: Log the error for debugging
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    const uuid = params.get("p1");

    if (!uuid || uuid.length !== 8) {
      // Handle the case where uuid is not present
      console.error("UUID is missing");
      setError("UUID is missing or invalid.");
      return;
    }

    try {
      const response = await axios.put(`${apiUrl}/eventCancelBooking`, {
        uuid:uuid,dateFormat:dateFormat,timeFormat:timeFormat
      },{
        headers: {
          "Content-Type": "application/json",
        },
      crossdomain: true
    });
      // console.log(response.data.message);

      if (response.status === 200) {
        if (response.data.message === "Not found.") {
          setDupCancelModalOpen(true);
        }else{
          navigate('/Forever/CentralWorld/cancelComplete', {
            state: {
              value: store
            }
          });
        }
      }
    } catch (error) {
      setErrorModalOpen(true); // Open the error modal
      console.error("API error:", error); // Optional: Log the error for debugging
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleErrorClose = () => {
    setErrorModalOpen(false); // Close the modal
  };

  const handleDupCancelClose = () => {
    setDupCancelModalOpen(false);

    navigate(`/Forever/CentralWorld`);
  };

  useEffect(() => {

    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const uuid = params.get("p1");
      try {
        const res = await axios.post(`${apiUrl}/getByUUID`, { uuid },{
          headers: {
            "Content-Type": "application/json",
          },
          crossdomain: true,
        });
        
        setStore(res.data.storeName);
        setDateFormat(res.data.odjDateTime.date_str);
        setTimeFormat(res.data.odjDateTime.time_str);
        setResultByUUID(res.data);
      } catch (error) {
        console.error("API error:", error); // Optional: Log the error for debugging
      }
    };

    // Call the function to fetch data
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (date) {
  //     const dateObj = new Date(date);
      
  //     // Convert to Thai time zone (Asia/Bangkok)
  //     const options = { 
  //       timeZone: 'Asia/Bangkok', 
  //       year: 'numeric', 
  //       month: 'short', 
  //       day: 'numeric' 
  //     };
      
  //     // Format the date in the desired format, e.g., "7 Nov 2024"
  //     const formattedDate = dateObj.toLocaleString('en-GB', options);
      
  //     setDateFormat(formattedDate);
  //   }
  // }, [date]);

  return (
    <div>
    <div className="text-center">
      <img
        src={eventImage}
        alt="Event"
        // className="img-fluid mb-4"
        style={{ width: "100%", height: "auto", maxWidth: "700px" }}
      />
    </div>
    <Container className="mt-5" style={{ textAlign: "center" }}>
      กรุณา ยืนยันการทำรายการ
      <br />
      Forever Makeup Workshop
      ณ Beacon 3 ชั้น 1 ศูนย์การค้าเซ็นทรัลเวิลด์
      <br />
      { dateFormat === null || dateFormat === undefined ? <Spinner color="secondary" /> :
      <div>
      วันที่ {dateFormat}&nbsp;&nbsp;
      เวลา {timeFormat}
      </div>
      }
      <br />
      <br />
      { loading ? <Spinner color="secondary" /> :
      <Button color="primary" onClick={handleUpdate}
      style={{
        width: "100%",
        maxWidth: "150px",

        backgroundColor: "#000",
        borderColor: "#000",
        color: "#FFF",
        borderRadius: "1px",
        marginBottom: "50px"
      }}
      >
        แก้ไข Edit
      </Button> }&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      { loading ? <Spinner color="secondary" /> :
      <Button color="primary" onClick={handleSubmit}
      style={{
        width: "100%",
        maxWidth: "150px",

        backgroundColor: "#000",
        borderColor: "#000",
        color: "#FFF",
        borderRadius: "1px",
        marginBottom: "50px"
      }}
      >
        ยกเลิก Cancel
      </Button> }

      <Modal isOpen={errorModalOpen} toggle={handleErrorClose}>
        <ModalHeader toggle={handleErrorClose}>
          มีปัญหาในการเชื่อมต่อ
        </ModalHeader>
        <ModalBody>
          ระบบมีปัญหาในการส่งข้อมูล กรุณาลองอีกครั้งในภายหลัง
          <br />
          There was an issue submitting your data. Please try again later.
        </ModalBody>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handleErrorClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dup Modal */}
      <Modal isOpen={dupCancelModalOpen} toggle={handleDupCancelClose}>
        <ModalHeader toggle={handleDupCancelClose}>แจ้งเตือน</ModalHeader>
        <ModalBody>
          หมายเลขโทรศัพท์หรืออีเมลถูกยกเลิกลงทะเบียนไปแล้ว
          <br />
        </ModalBody>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handleDupCancelClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
    </div>
  );
};

export default CancelPageForeverCentralWorld;
