import React from "react";
import DataTable from 'react-data-table-component';
import {
  Button,
  Input,
  InputGroup,
  Spinner
} from "reactstrap";

const DataTableUserPage = ({
  userData,
  onStatusChange,
  onSaleChange,
  onRemarkChange,
  showStatus,
  showSale,
  showRemark,
  isUpdating
}) => {
  const getStatusColor = (status) => {
    const currentStatus = showStatus !== null ? showStatus : status;
    switch (currentStatus) {
      case 1: return 'green';
      case 2: return 'red';
      default: return 'grey';
    }
  };

  const formatNumber = (value) => {
    if (!value) return '';
    // Convert to number and format with thousand separator and 2 decimal places
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const columns = [
    {
      name: 'วันที่',
      selector: row => row.date_str,
      sortable: true,
      width: '110px',
      center: true
    },
    {
      name: 'รอบ',
      selector: row => row.time_str,
      sortable: true,
      width: '70px',
      center: true
    },
    {
      name: 'ชื่อ-นามสกุล',
      selector: row => `${row.first_name} ${row.last_name}`,
      sortable: true,
      width: '200px',
    },
    {
      name: 'เบอร์โทร',
      selector: row => row.mobile_phone,
      sortable: true,
      width: '130px',
      center: true
    },
    {
      name: 'สถานะมารับบริการ',
      selector: row => row.status_bc,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            backgroundColor: getStatusColor(row.status_bc),
            marginRight: '10px'
          }} />
          {((showStatus !== null && showStatus !== 2) || (row.status_bc !== null && row.status_bc !== 2)) && (
            <Button 
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "2px",
              }}
              onClick={() => onStatusChange(row)}
              disabled={isUpdating}
            >
              {isUpdating ? (
                <Spinner size="sm" color="light" />
              ) : (
                'แก้ไข'
              )}
            </Button>
          )}
        </div>
      ),
      width: '140px',
      center: true
    },
    {
      name: 'ยอดซื้อ',
      selector: row => row.sale_bc,
      sortable: true,
      cell: row => (
        <InputGroup>
          <Input
            style={{
              borderRadius: "2px",
            }}
            value={showSale !== "" ? formatNumber(showSale) : formatNumber(row.sale_bc)}
            onClick={() => onSaleChange(row)}
            disabled
          />
          {((showStatus !== null && showStatus !== 2) || (row.status_bc !== null && row.status_bc !== 2)) && (
          <Button
            style={{
              backgroundColor: "#000",
              borderColor: "#000",
              color: "#FFF",
              borderRadius: "2px",
            }}
            onClick={() => onSaleChange(row)}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <Spinner size="sm" color="light" />
            ) : (
              'แก้ไข'
            )}
          </Button>
          )}
        </InputGroup>
      ),
      width: '230px'
    },
    {
      name: 'หมายเหตุ',
      selector: row => row.remarks,
      sortable: true,
      cell: row => (
        <InputGroup>
          <Input
            style={{
              borderRadius: "2px",
            }}
            value={showRemark !== "" ? showRemark : row.remarks}
            onClick={() => onRemarkChange(row)}
            disabled
          />
          {((showStatus !== null && showStatus !== 2) || (row.status_bc !== null && row.status_bc !== 2)) && (
          <Button
            style={{
              backgroundColor: "#000",
              borderColor: "#000",
              color: "#FFF",
              borderRadius: "2px",
            }}
            onClick={() => onRemarkChange(row)}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <Spinner size="sm" color="light" />
            ) : (
              'แก้ไข'
            )}
          </Button>
          )}
        </InputGroup>
      ),
      width: '300px'
    },
  ];

  return (
    <div>
      {userData.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
          <h4>ไม่พบข้อมูลค้าสำหรับช่วงเวลานี้</h4>
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={userData}
          striped
          highlightOnHover
          noHeader
          progressPending={isUpdating}
          progressComponent={
            <div style={{ padding: '24px' }}>
              <Spinner color="dark" />&nbsp;&nbsp;ระบบกำลังดำเนินการบันทึกข้อมูล
            </div>
          }
        />
      )}
    </div>
  );
};

export default DataTableUserPage;
